#root~iframe[style*="2147483647"] { z-index: -1000 !important; display: none; }
.l { border: 1px solid red; }
.pt-100 { padding-top: 100px; }
.pb-70 { padding-bottom: 70px; }
.ptb-100 { padding-top: 100px; padding-bottom: 100px; }
.pb-100 { padding-bottom: 100px; }
.pt-50 { padding-top: 50px; }
.pt-100 { padding-top: 100px; }
.pb-70 { padding-bottom: 70px; }
.ptb-100 { padding-top: 100px; padding-bottom: 100px; }
.pb-100 { padding-bottom: 100px; }
.was-validated .react-select.is-invalid div { border-color: #dc3545 !important; border-radius: 5px !important; }
.was-validated .react-select.is-invalid div:hover { border-color: #dc3545 !important; }
.was-validated .react-select.is-valid div { border-color: #198754 !important; border-radius: 5px !important; }
.was-validated .react-select.is-valid div:hover { border-color: #198754 !important; }
.was-validated .react-select div:hover { border-color: rgb(0, 1, 0) !important; }
.was-validated .form-control:invalid { border-color: #dc3545 !important; }
.needs-validation div:has(.invalid-custom-feedback) .invalid-feedback { display: none; }
@-moz-document url-prefix() {
.needs-validation div .invalid-custom-feedback~.invalid-feedback { display: none; }
}
.form-control.is-invalid { border-color: #dc3545 !important; }
.pagination-sm .page-item.previous.disabled { opacity: 0.6; cursor: not-allowed; }
.pagination-sm .page-item.next.disabled { opacity: 0.6; cursor: not-allowed; }
.pagination-sm .page-item.previous .page-link { width: 74px; font-weight: 500; }
.pagination-sm .page-item.next .page-link { width: 74px; font-weight: 500; }
.pagination-sm .page-item.active .page-link { background-color: var(--blue); border-color: var(--blue); color: #fff; }
.pagination-sm .page-item.active .page-link:hover { background-color: var(--blue); border-color: var(--blue); color: #fff; }
.pagination-sm .page-item:hover .page-link { background-color: rgba(var(--blue-rgb), 0.15); border-color: rgba(var(--blue-rgb), 0.15); }
.pagination-sm .page-item .page-link { height: 30px; min-width: 30px; text-decoration: none; display: inline-flex; border: 1px solid #dee2e6; margin-left: 2px; cursor: pointer; text-align: center; justify-content: center; align-items: center; border-radius: 0.25rem; font-size: 14px; color: #212529; }
.needs-validation .form-control { background-image: none !important; }
.react-select { border-radius: 0 !important; }
.was-validated .react-select.is-invalid div { border-color: #dc3545 !important; border-radius: 5px !important; }
.was-validated .react-select.is-invalid div:hover { border-color: #dc3545 !important; }
.was-validated .react-select.is-valid div { border-color: #198754 !important; border-radius: 5px !important; }
.was-validated .react-select.is-valid div:hover, .was-validated .react-select.is-valid div:active { border-color: #198754 !important; }
.was-validated .react-select div:hover { border-color: rgba(var(--dark-blue-rgb), 0.5) !important; }
.modal-backdrop { z-index: 11; }
.mobile-header-menu ul li.active > a, .mobile-header-menu ul li:hover > a { color: #0d799d; }
::-webkit-scrollbar { width: 5px !important; height: 5px !important; background: rgba(#0d799d, 0.1) !important; }
::-webkit-scrollbar-track { border-radius: 10px !important; }
::-webkit-scrollbar-thumb { border-radius: 10px !important; background-color: rgba(#0d799d, 0.75) !important; }
::-webkit-scrollbar-thumb:hover { background-color: #0d799d !important; }
.swiper-slide-active .slider-content, .swiper-slide-active .slider-content-02 { opacity: 1; }
.swiper-slide-active .slider-content .title, .swiper-slide-active .slider-content-02 .title { -webkit-animation-name: fadeInUpBig; animation-name: fadeInUpBig; -webkit-animation-delay: 0.4s; animation-delay: 0.4s; -webkit-animation-duration: 1s; animation-duration: 1s; -webkit-animation-fill-mode: both; animation-fill-mode: both; }
.swiper-slide-active .slider-content p, .swiper-slide-active .slider-content-02 p { -webkit-animation-name: fadeInUpBig; animation-name: fadeInUpBig; -webkit-animation-delay: 0.8s; animation-delay: 0.8s; -webkit-animation-duration: 1s; animation-duration: 1s; -webkit-animation-fill-mode: both; animation-fill-mode: both; }
.swiper-slide-active .slider-content .btn, .swiper-slide-active .slider-content-02 .btn { -webkit-animation-name: fadeInUpBig; animation-name: fadeInUpBig; -webkit-animation-delay: 1.2s; animation-delay: 1.2s; -webkit-animation-duration: 1s; animation-duration: 1s; -webkit-animation-fill-mode: both; animation-fill-mode: both; }
.swiper-slide-active .slider-images { opacity: 1; }
.swiper-slide-active .slider-images img { -webkit-animation-name: fadeInRightBig; animation-name: fadeInRightBig; -webkit-animation-delay: .5s; animation-delay: .5s; -webkit-animation-duration: 1.3s; animation-duration: 1.3s; -webkit-animation-fill-mode: both; animation-fill-mode: both; }

/* Raza  */
.banner-sldier-container { margin-top: 94px; }
.banner-slider-content { position: relative; min-height: 750px; display: flex; flex-direction: column; justify-content: center;padding: 50px 0 50px; }
.banner-slider-text { position: relative; z-index: 9999; text-shadow: 0 0 12px #00000091; }
.banner-slider-img::before { content: ''; position: absolute; width: 100%; height: 100%; top: 0; left: 0; background: rgba(0, 0, 0, .3) }
.banner-slider-text h1, .banner-slider-text h2 { font-size: 74px; color: #fff; margin-bottom: 16px; }
.banner-slider-text p { font-size: 24px; color: #fff; }
.banner-slider-img { position: absolute; width: 100%; height: 100%; top: 0; left: 0; }
.banner-slider-img img { width: 100%; height: 100%; object-fit: cover; }
.banner-sldier-container .swiper-button-prev, .banner-sldier-container .swiper-button-next { display: none; }
.banner-sldier-container .swiper-pagination .swiper-pagination-bullet { background: #fff; width: 10px; height: 10px; margin: 4px 8px !important; opacity: 1; box-shadow: 0 0 20px rgb(0 0 0 / 50%); }
.banner-sldier-container .swiper-pagination .swiper-pagination-bullet-active { outline: 1px solid #fff; outline-offset: 4px; background: #fff; }
.banner-sldier-container .swiper-pagination { width: auto; left: 50%; background-color: #ffffff59; padding: 6px 6px 0px; transform: translateX(-50%); backdrop-filter: blur(10px); border-radius: 15px; }
.canvas-menu .btn-close { margin-right: 10px; }
.swiper-slide-active .banner-slider-text{
    animation-name: fadeInUpBig;
    -webkit-animation-delay: .4s;
    animation-delay: .4s;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@keyframes fadeInUpBig{
	0% {
        opacity: 0;
        -webkit-transform: translate3d(0,50px,0);
        transform: translate3d(0,50px,0);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
}



.contact-info.login-info-img { padding: 0; }
.contact-info.login-info-img img { height: 100%; width: 100%; position: relative; object-fit: cover; }
.register-info-img { height: 100%; position: relative; }
.register-info-img img { height: 100%; width: 100%; object-fit: cover; }
.banner-sldier-wrapper { min-height: 800px; display: flex; }
.banner-sldier-wrapper .swiper-container { height: 100%; display: flex; }
.banner-sldier-wrapper .swiper-container .swiper-slide { display: flex; align-items: center; flex-wrap: wrap; }
.banner-sldier-wrapper .swiper-pagination-bullet { background: black; opacity: 1; width: 9px; height: 9px; border-radius: 5px; margin: 2px 8px !important; }
.banner-sldier-wrapper .swiper-pagination-bullet-active { background: #f8ae00; outline: 1px solid #f8ae00; outline-offset: 3px; }
.form-control { outline: none !important; box-shadow: none !important; }
.register-info-content { position: absolute; width: 100%; background-color: rgb(0 0 0 / 34%); top: 0; padding: 20px; height: 100%; display: flex; flex-flow: column; align-items: center; justify-content: center; }
.register-info-content h4 { color: #fff; font-size: 36px; margin-bottom: 10px; text-shadow: 0 0 4px #000000b5; font-weight: 700; }
.register-info-content P { color: #fff; font-size: 20px; line-height: 1.1; margin-bottom: 15px; text-shadow: 0 0 4px #000000b5; font-weight: 700; }
.register_form_wrapper .form-check-input { box-shadow: none !important; }
.single-benefit img { max-height: 84px; }

/**** 19-10-2023 *****/
.section-title-03 { margin-bottom: 30px; }
.section-title-03 p { line-height: 24px; margin-bottom: 14px; }
.section-title-03.text-center p { max-width: 1000px; margin: 0 auto 14px; }
.section-title-03 p:last-child { margin-bottom: 0; }
.what-we-do-content { height: 100%; display: flex; flex-flow: column; justify-content: center; padding-left: 20px; }
.what-we-do-bg { position: absolute; width: 50%; left: 0; top: 0; height: 100%; }
.what-we-do-bg img { width: 100%; height: 100%; object-fit: cover; }
.project_list { width: calc(100% + 20px); margin-left: -10px;display: flex;flex-wrap: wrap; }
.project_item { width: 25%; padding: 10px; }
.project_box { position: relative; width: 100%; display: block; padding-bottom: 80%; }
.project_box > img { position: absolute; width: 100%; height: 100%; top: 0; left: 0; object-fit: cover; }
.project_overlay {
    position: absolute;
    display: flex;
    flex-flow: column;
    background-color: rgb(0 0 0 / 25%);
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    top: 10px;
    left: 10px;
    outline: 1px solid #ffffffc9;
    outline-offset: 3px;
    opacity: 0;
    transform: scale(.8);
    transition: all .2s ease-in;
    padding: 20px;
    }
.project_overlay h4 { color: #fff;
    font-size: 16px;
    margin-bottom: 10px;
    line-height: 1.1;
    font-weight: 700;
    text-shadow: 0 0 5px #0000009c;
    text-transform: uppercase;
    font-family: inherit; }
    .project_overlay .project_cat {
        color: #fff;
        font-weight: 500;
        text-align: end;
        padding-right: 12px;
        border-right: 2px solid #fff;
        font-size: 15px;
        line-height: 18px;
        margin-top: auto;
    }
.project-expand-arrow { width: 16px; height: 16px; }
.project-expand-arrow img { width: 100%; filter: brightness(0) invert(1); vertical-align: super; }
.project_box:hover .project_overlay { opacity: 1; transform: scale(1); }
.blockquote-text-block {    position: relative;
    margin-top: 30px;
    max-width: 360px;}
.blockquote-text-block blockquote::before { display: none; }
.blockquote-text-block blockquote { padding-left: 54px;
    line-height: 24px; line-height: 1.1;
    margin: 0;
    font-family: Lato,Arial, Helvetica, sans-serif;
    letter-spacing: 3px;
    font-weight: 400;
    color: #aa8f6a;     text-transform: uppercase;
    font-size: 18px; }
.blockquote-text-icon { font-size: 40px;
    position: absolute;
    top: 0;
    line-height: 40px;
    color: #aa8f6a;
    opacity: .5; }
.team-wrapper-outer .single-team .team-images { width: 100%; padding-bottom: 120%; position: relative; }
.team-wrapper-outer .single-team .team-images img { position: absolute; width: 100%; height: 100% !important; object-fit: cover; transform: none !important; }
.team-wrapper-outer .single-team .team-content { position: absolute; bottom: 0; width: 100%; padding: 100px 20px 20px; background: linear-gradient(360deg, #0d799d, transparent); opacity: 0; transform: translateY(40px); transition: all 0.2s linear; }
.team-wrapper-outer .single-team { position: relative; overflow: hidden; height: 100%; margin: 0 !important; }
.team-wrapper-outer .single-team:hover .team-content { opacity: 1; transform: none; }
.team-wrapper-outer .single-team .team-content .name { color: #fff; }
.team-wrapper-outer .single-team .team-content .designation { color: #ffffffb3; }
.team-wrapper-outer .single-team .team-intro-text { padding: 20px; background-color: #0d799d; color: #fff; height: 100%; }
.team-wrapper-outer .single-team .team-intro-text p { line-height: 24px; }
.block-top-content { padding: 24px; background-color: #fff9ed; align-items: center; }
.block-top-text { width: 54%; padding-right: 24px; }
.block-top-img { width: 46%; }
.service-text-img-2 img, .block-top-img img { width: 100%; }
.service-text-img-2 { max-width: 50%; }
.block-top-text .section-title-03 { margin: 0; }
.service-text { padding: 30px; box-shadow: 0 0 20px rgba(0, 0, 0, 0.1); width: calc(100% + 200px); position: relative; background: #fff; margin: 30px 0; }
.service-text.right { margin-left: -200px; }
/* 20-10-2021 */
.footer-social-link {
    width: 40px;
    height: 40px;
    background-color: #0d799d;
    color: #fff !important;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    box-shadow: 0 0 0px rgba(0,0,0,0.2);
    transition: all .3s ease-in;
}

.footer-social-link + .footer-social-link {
    margin-left: 14px;
    box-shadow: 0 0 0px rgba(0,0,0,0.2);
}

.footer-social-link:hover {
    transform: translateY(-3px);
    box-shadow: 0 8px 20px rgb(0 0 0 / 20%);
}
.footer-social-link.fs-f:hover {
    background-color: #3b5998;
}

.footer-social-link.fs-i:hover {
    background-color: #C32AA3;
}

.footer-social-link.fs-t:hover {
    background-color: #0084b4;
}

.footer-social-link.fs-y:hover {
    background-color: #FF0000;
}
.project-tabs {
    margin-bottom: 40px;
}

.project-tab-list {
    justify-content: center; margin-bottom: -10px;
}

.project-tab-item {
    margin: 0 10px 10px;
}

.project-tab-btn {
    background: transparent;
    border: none;
    padding: 0 10px;
    outline-offset: 2px;
    transition: all .3s ease-in;
    color: #999;
    position: relative;
    margin: 0 10px;
}
.project-tab-btn::before{
    content: "";
    width: calc(100% + 20px);
    height: 1px;
    background-color: #333;
    display: inline-block;
    position: absolute;
    left: -10px;
    top: 50%;
    z-index: -1;
    transform: scaleX(0);
    transition: all .15s ease-in;
}
.project-tab-btn.active::before{
    transform: scaleX(1);
}

.project-tab-btn.active, .project-tab-btn:hover {
    background-color: #333;
    color: #fff;
}
.service-modal .service-modal-outer {
    position: relative;
    display: flex;
    flex-wrap: wrap;
}

.service-modal .service-modal-outer .btn-close {
    position: absolute;
    opacity: 1;
    top: 10px;
    right: 15px;
}

.service-modal .service-modal-imgs {
    width: 50%;    border-radius: 3px;
    overflow: hidden;
}

.service-modal-imgs  .swiper-pagination .swiper-pagination-bullet { background: #fff; width: 8px; height: 8px; margin: 2px 6px !important; opacity: 1; box-shadow: 0 0 160px rgb(0 0 0 / 50%); }
.service-modal-imgs  .swiper-pagination .swiper-pagination-bullet-active { outline: 1px solid #fff; outline-offset: 2px; background: #fff; }
.service-modal-imgs  .swiper-pagination {width: auto;
    left: 50%;
    background-color: #ffffff59;
    padding: 0px 4px 0px;
    transform: translateX(-50%);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(5px);
    border-radius: 16px;
} 


.service-modal .service-modal-img {
    width: 100%;     padding-bottom: 74%;
}

.modal-backdrop {
    z-index: 99;
}

.service-modal .service-modal-img img {
    width: 100%;
    height: 100%;
    top:0;
    left: 0;
    object-fit: cover;
    position: absolute;
}

.service-modal .service-modal-content {
    width: 50%;
    padding: 40px;
}

.service-modal .service-modal-content h4 {
    font-size: 27px;
    color: #555555;
    margin-bottom: 14px;
    font-weight: 700;
}

.service-modal-imgs .swiper-button-prev, .service-modal-imgs .swiper-button-next {
    background: #fff;
    width: 40px;
    height: 60px;
}

.service-modal-imgs .swiper-button-prev:after, .service-modal-imgs .swiper-button-next:after {
    font-size: 20px;
    color: #000;
}

.service-modal-imgs .swiper-button-prev {
    left: 0;
    border-radius: 0 40px 40px 0;
    padding: 4px 16px 4px 10px !important;
}

.service-modal-imgs .swiper-button-next {
    right: 0;
    padding: 4px 10px 4px 16px !important;
    border-radius: 40px 0 0 40px;
}

.custom-tabs .nav-tabs {
    background-color: #0d799d;
}

.custom-tabs .nav-tabs .nav-item .nav-link {
    background-color: transparent;
    color: #fff;
    padding: 10px 40px 7px;
    border-radius: 0 !important;
    margin: 0;
    border: 0 !important;
    opacity: .8;
    border-bottom: 3px solid #f1eee500 !important;
}

.custom-tabs .nav-tabs .nav-item .nav-link.active {
    opacity: 1;
    border-bottom: 3px solid #f1eee5 !important;
}

.custom-tabs  .nav-tabs .nav-item .nav-link svg {
    margin-right: 5px;
    font-size: 22px;
    vertical-align: text-top;
}

.w-ghost-button.large {
    padding: 12px 40px;
    font-weight: 700;
    line-height: 16px;
    font-size: 14px;
    letter-spacing: 3px;
}

.w-ghost-button {
    position: relative;
    z-index: 1;
    display: inline-block;
    vertical-align: top;
    border-width: 1px;
    border-style: solid;
    border-color: #8accff;
    color: #8accff;
    background: none;
    font-size: 12px;
    line-height: 14px;
    min-width: 120px;
    cursor: pointer;
    padding: 8px 20px;
    text-transform: uppercase;
    text-align: center;
    outline: none;
    overflow: hidden;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}
.w-ghost-button:after {
    position: absolute;
    content: '>';
    font-family: 'monospace';
    opacity: 0;
    right: 16px;
    top: 50%;
    line-height: 0;
    color: inherit;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    -ms-transform: translateX(40px);
    -webkit-transform: translate3d(40px, 0, 0);
    transform: translate3d(40px, 0, 0);
    -webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
    transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
}
.w-ghost-button:hover:after {
    opacity: 1;
    right: 10px;
    -ms-transform: translateX(0), translateY(0);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}
.w-ghost-button > span {
    display: inline-block;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    -ms-transform: translateX(0);
    -webkit-transform: translateX(0);
    transform: translateX(0);
}
.w-ghost-button.large:hover > span {
    -ms-transform: translateX(-20px);
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
}

.dashboardCard {
    background-color: #fff;
    border-radius: 10px;
    border: none;
    position: relative;
    margin-bottom: 30px;
    box-shadow: 0 0.46875rem 2.1875rem rgba(90,97,105,0.1), 0 0.9375rem 1.40625rem rgba(90,97,105,0.1), 0 0.25rem 0.53125rem rgba(90,97,105,0.12), 0 0.125rem 0.1875rem rgba(90,97,105,0.1);
}

.dashboardCard .card-statistic-3 .card-title, .card-number{
    color: #fff !important;
    font-family: 'Lato', sans-serif;
}

.l-bg-blue-dark {
    background: linear-gradient(to right, #373b44, #4286f4) !important;
    color: #fff;
}

.l-bg-green-dark {
    background: linear-gradient(to right, #0a504a, #38ef7d) !important;
    color: #fff;
}

.l-bg-orange-dark {
    background: linear-gradient(to right, #a86008, #ffba56) !important;
    color: #fff;
}

.dashboardCard .card-statistic-3 .card-icon-large .fa {
    font-size: 50px;
}

.dashboardCard .card-statistic-3 .card-icon {
    text-align: center;
    line-height: 50px;
    margin-left: 15px;
    color: #000;
    position: absolute;
    right: -5px;
    top: 20px;
    opacity: 0.1;
}

.l-bg-green {
    background: linear-gradient(135deg, #23bdb8 0%, #43e794 100%) !important;
    color: #fff;
}

.l-bg-orange {
    background: linear-gradient(to right, #f9900e, #ffba56) !important;
    color: #fff;
}

.l-bg-cyan {
    background: linear-gradient(135deg, #289cf5, #84c0ec) !important;
    color: #fff;
}

/* 4-11-2023 start */
.job_breadcrumb { padding: 14px; background-color: #dbdbdb; }
.job_breadcrumb_list { display: flex; }
.job_breadcrumb_list li + li { padding-left: 8px; margin-left: 8px; }
.job_breadcrumb_list li { position: relative; }
.job_breadcrumb_list li + li::before { content: '/'; position: absolute; left: -2px; top: 0px; line-height: 16px; font-size: 14px; font-weight: 700; opacity: .8; }
.job_breadcrumb_link { font-family: 'Lato'; font-weight: 700; color: #555; font-size: 12px; line-height: 16px; display: block; }
.inner_ct_item { margin-top: 2px; }
.inner_ct_box { background-color: #f1f1f1; padding: 7px 14px; align-items: center;}
.inner_ct_drop-btn { border: none; background-color: #e1e1e1; width: 30px; height: 30px; border-radius: 50%; padding: 3px; display: flex; align-items: center; justify-content: center; color: #666; }
.inner_ct_drop-btn svg { width: 18px; }
.inner_ct_drop .dropdown-menu li a { padding: 6px 10px; display: block; font-size: 12px; font-weight: 600; color: #555; line-height: 16px; }
.inner_ct_drop .dropdown-menu { min-width: 110px; border-color: #ededed; }
.inner_ct_drop .dropdown-menu li a svg { width: 14px; margin-right: 4px; height: 14px; vertical-align: text-bottom;}
.inner_ct_drop .dropdown-menu li a:hover { background-color: #f3f3f3; }
.inner_ct_box .inner_ct_link { font-size: 12px; font-weight: 600; color: #555 !important;}
.inner_ct_box .inner_ct_link svg { font-size: 14px; vertical-align: text-top;  margin-right: 5px; color: #0d799d; }
.ct-empty-folder { padding: 6px 10px; border-radius: 3px; background: #d9d9d9; border: 1px solid #cbcbcb; }
.ct-empty-folder .inner_ct_link { font-size: 14px; font-weight: 600; color: #8d8d8d; }
.ct-empty-folder .inner_ct_link svg { font-size: 18px; vertical-align: sub; margin-right: 8px; }
.ct_box { margin-bottom: 8px; border-radius: 4px; border: 1px solid #e9e9e9; background-color: #f5f5f5; padding: 8px; height: 100%; }
.ct_box .ct_box_ul .ct_box_li { display: flex; font-size: 12px; font-weight: 500; line-height: 16px; color: #666; flex-wrap: wrap; }
.ct_box .ct_box_ul .ct_box_li p {font-size: inherit; line-height: inherit;}
.ct_box .ct_box_ul .ct_box_li strong { color: #333; }
.ct_box .ct_box_ul .ct_box_li + .ct_box_li { margin-top: 8px; }
.ct_box .ct_box_ul .ct_box_li_left { width: 50%; padding-right: 14px; }
.ct_box .ct_box_ul .ct_box_li_right { width: 50%; }
.ct_box .ct_box_ul .ct_box_li_right  a{ text-decoration: underline; }
@media (max-width:767.98px){
    .ct_box .ct_box_ul .ct_box_li_left, .ct_box .ct_box_ul .ct_box_li_right {  width: 100%; padding: 0; }
}
/* 4-11-2023 end */            

@media only screen and (max-width:1199.98px) {
    .container { width: calc(100% - 14px); max-width: 1200px; }
    .custom-tabs .nav-tabs .nav-item .nav-link { padding: 10px 20px 7px; }
    .banner-slider-text h1, .banner-slider-text h2 { font-size: 70px;  }
    .banner-slider-text p { font-size: 22px; line-height: 30px; }
    
}
@media only screen and (max-width:991.98px) {
    .what-we-do-content { padding: 0; }
    .project_item:nth-child(2) .project_box { padding-bottom: 80.9%; }
    .team-wrapper-outer .single-team .team-content { opacity: 1; transform: none; }
    .block-top-text { width: 100%; padding: 0; }
    .block-top-img { width: 100%; margin-top: 30px; }
    .service-text .section-title-03 .title { font-size: 40px; }
    .product-tabs-content { width: calc(100% + 18px); }
    .custom-tabs .nav-tabs .nav-item .nav-link { padding: 10px 20px 7px; }
    .custom-tabs .nav-tabs { flex-flow: column; }
    .custom-tabs .nav-tabs .nav-item .nav-link { width: 100%; text-align: start; border-left: 3px solid #f1eee500 !important; border-bottom: 0 !important; }
    .custom-tabs .nav-tabs .nav-item .nav-link.active { border-left: 3px solid #f1eee5 !important; border-bottom: 0 !important; }
    .banner-slider-content { min-height: 650px; }
}
@media only screen and (max-width:767.98px) {
    .section-title-03{margin-bottom: 24px;}
    .banner-sldier-wrapper { min-height: 600px; }
    .banner-sldier-wrapper .swiper-container .swiper-slide { flex-flow: column; }
    .register-info-content h4 { font-size: 30px; }
    .project_item, .project_item:nth-child(2) { width: 100% !important; }
    .team-wrapper-outer .single-team .team-content { opacity: 1; transform: none; }
    .service-text { margin: 0 !important; width: 100%; padding: 30px 20px; }
    .service-text .section-title-03 .title { font-size: 27px; line-height: 33px; }
    .service-modal .service-modal-imgs, .service-modal .service-modal-content { width: 100%; }
    .modal .modal-dialog .modal-content .btn-close { background-color: #fff; background-size: 12px; }
    .service-modal .service-modal-content {  padding: 0; margin-top: 24px; }
    .service-modal-imgs .swiper-button-prev, .service-modal-imgs .swiper-button-next { width: 30px; }
    .margin-top { margin-top: 90px; }
    .banner-slider-text h1, .banner-slider-text h2 { font-size: 50px; }
    .banner-slider-content { min-height: 550px; }
    .banner-slider-text p { font-size: 20px; line-height: 26px; }
}
@media only screen and (max-width:575px) {
    .login-contact-form { padding-top: 30px; }
}
/*==================================================================
12 LOADER
/*==================================================================*/
#preloader {
    position: fixed;
    z-index: 8888;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 1;
    background: #fff;
    -webkit-transition: opacity 0.5s;
    transition: opacity 0.5s;
}
#preloader.loaded{
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
}
#loading-animation {
    position: absolute;
    z-index: 9999;
    width: 100%;
    text-align: center;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.spinner {
    display:inline-block;
}

.w-loader {
  display: inline-block;
}
.w-loader:after {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 100%;
    border-top: 1px solid #545a6a;
    border-bottom: 1px solid #d4d4db;
    border-left: 1px solid #545a6a;
    border-right: 1px solid #d4d4db;
    -webkit-animation: rotate 0.6s linear infinite;
    animation: rotate 0.6s linear infinite;
}
@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotateZ(-360deg);
    transform: rotateZ(-360deg);
  }
  100% {
    -webkit-transform: rotateZ(0deg);
    transform: rotateZ(0deg);
  }
}

@keyframes rotate {
  0% {
    -webkit-transform: rotateZ(-360deg);
    transform: rotateZ(-360deg);
  }
  100% {
    -webkit-transform: rotateZ(0deg);
    transform: rotateZ(0deg);
  }
}

/** Post Loader **/
.post-loader{
    margin: 10px 30px;
}

.ball-pulse,
.ball-pulse > div {
    position: relative;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
}
.ball-pulse {
    display: inline-block;
    font-size: 0;
    color: #fff;
    width: 108px;
    height: 36px;
}

.ball-pulse > div {
    display: inline-block;
    float: none;
    background-color: #8accff;
    border: 0 solid #8accff;
}

.ball-pulse > div:nth-child(1) {
    -webkit-animation-delay: -200ms;
       -moz-animation-delay: -200ms;
         -o-animation-delay: -200ms;
            animation-delay: -200ms;
}

.ball-pulse > div:nth-child(2) {
    -webkit-animation-delay: -100ms;
       -moz-animation-delay: -100ms;
         -o-animation-delay: -100ms;
            animation-delay: -100ms;
}

.ball-pulse > div:nth-child(3) {
    -webkit-animation-delay: 0ms;
       -moz-animation-delay: 0ms;
         -o-animation-delay: 0ms;
            animation-delay: 0ms;
}

.ball-pulse > div {
    width: 20px;
    height: 20px;
    margin: 8px;
    border-radius: 100%;
    -webkit-animation: ball-pulse 1s ease infinite;
       -moz-animation: ball-pulse 1s ease infinite;
         -o-animation: ball-pulse 1s ease infinite;
            animation: ball-pulse 1s ease infinite;
}

@-webkit-keyframes ball-pulse {
    0%,
    60%,
    100% {
        opacity: 1;
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    30% {
        opacity: .1;
        -webkit-transform: scale(.01);
                transform: scale(.01);
    }
}

@keyframes ball-pulse {
    0%,
    60%,
    100% {
        opacity: 1;
        -webkit-transform: scale(1);
           -moz-transform: scale(1);
             -o-transform: scale(1);
                transform: scale(1);
    }
    30% {
        opacity: .1;
        -webkit-transform: scale(.01);
           -moz-transform: scale(.01);
             -o-transform: scale(.01);
                transform: scale(.01);
    }
}

/** Loader 1 **/
.loader-timer,
.loader-timer > div {
    position: relative;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.loader-timer {
    display: inline-block;
    width: 64px;
    height: 64px;
    font-size: 0;
    color: #fff;
}

.loader-timer > div {
    display: inline-block;
    width: 64px;
    height: 64px;
    float: none;
    background: transparent;
    border: 4PX solid #0d799d;
    border-radius: 100%;
}

.loader-timer > div:before,
.loader-timer > div:after {
    display: block;
    position: absolute;
    top: 28px;
    left: 28px;
    width: 4px;
    margin-top: -2px;
    margin-left: -2px;
    border-radius: 4px;
    content: "";
    background: #0d799d;
    -webkit-transform-origin: 2px 2px 0;
    transform-origin: 2px 2px 0;
    -webkit-animation: timer-loader 1250ms infinite linear;       
    animation: timer-loader 1250ms infinite linear;
    -webkit-animation-delay: -625ms;       
    animation-delay: -625ms;
}

.loader-timer > div:before {
    height: 24px;
}

.loader-timer > div:after {
    height: 16px;
    -webkit-animation-duration: 15s;       
    animation-duration: 15s;
    -webkit-animation-delay: -7.5s;      
    animation-delay: -7.5s;
}

@-webkit-keyframes timer-loader {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes timer-loader {
    0% {
        -webkit-transform: rotate(0deg);           
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);          
        transform: rotate(360deg);
    }
}

/** Loader 2 **/
.loader-square-spin,
.loader-square-spin > div {
    position: relative;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.loader-square-spin {
    display: inline-block;
    width: 52px;
    height: 52px;
    font-size: 0;
    color: #fff;
}

.loader-square-spin > div {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 24px;
    height: 24px;
    float: none;
    background-color: #8accff;
    border: 0 solid #8accff;
    margin-top: -12px;
    margin-left: -12px;
    border-radius: 100%;
    -webkit-animation: square-spin 1s infinite ease-in-out;
    animation: square-spin 1s infinite ease-in-out;
}

.loader-square-spin > div:nth-child(1) {
    top: 0;
    left: 0;
    -webkit-animation-delay: -.875s;
    animation-delay: -.875s;
}

.loader-square-spin > div:nth-child(2) {
    top: 0;
    left: 50%;
    -webkit-animation-delay: -.75s;
    animation-delay: -.75s;
}

.loader-square-spin > div:nth-child(3) {
    top: 0;
    left: 100%;
    -webkit-animation-delay: -.625s;
    animation-delay: -.625s;
}

.loader-square-spin > div:nth-child(4) {
    top: 50%;
    left: 100%;
    -webkit-animation-delay: -.5s;
    animation-delay: -.5s;
}

.loader-square-spin > div:nth-child(5) {
    top: 100%;
    left: 100%;
    -webkit-animation-delay: -.375s;
    animation-delay: -.375s;
}

.loader-square-spin > div:nth-child(6) {
    top: 100%;
    left: 50%;
    -webkit-animation-delay: -.25s;
    animation-delay: -.25s;
}

.loader-square-spin > div:nth-child(7) {
    top: 100%;
    left: 0;
    -webkit-animation-delay: -.125s;
    animation-delay: -.125s;
}

.loader-square-spin > div:nth-child(8) {
    top: 50%;
    left: 0;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
}

@-webkit-keyframes square-spin {
    0%,
    40%,
    100% {
        -webkit-transform: scale(.4);
        transform: scale(.4);
    }
    70% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes square-spin {
    0%,
    40%,
    100% {
        -webkit-transform: scale(.4);
        transform: scale(.4);
    }
    70% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

/** Loader 3 **/
.loader-image{
    position: relative;
    display: inline-block;
    width: 120px;
    height: 120px;
}

.loader-image img{
    position: absolute;
    display: inline-block;
    top: 50%;
    left: 50%;
    max-height: 70%;
    max-width: 70%;
    -webkit-transform: translate3d(-50%, -50%, 0);
    transform: translate3d(-50%, -50%, 0);
}

.loader-box{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.loader-circle {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    box-shadow: inset 0 0 0 3px #003648;
    opacity: 0.25;
    margin-left: -60px;
    margin-top: -60px;
}

.loader-line-mask {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 50%;
    height: 100%;
    margin-left: -50%;
    margin-top: -50%;
    overflow: hidden;
    -webkit-transform-origin: 60px 60px;
    transform-origin: 60px 60px;
    -webkit-mask-image: -webkit-linear-gradient(top, #000000, rgba(0, 0, 0, 0));
    -webkit-animation: loader-spin 1.2s infinite linear;
    animation: loader-spin 1.2s infinite linear;
}

.loader-line-mask .loader-line {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    box-shadow: inset 0 0 0 4px #003648;
    opacity: 0.75;
}


@-webkit-keyframes loader-spin{ 
    0% { 
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    } 
    100% { 
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes loader-spin{ 
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    } 
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.action-btn {
    margin: 0;
    padding: 0;
    height: 25px;
    width: 25px;
    line-height: 0;
    margin: 0;
    border: 1px solid gray;
    opacity: .8
}

.btn{
    z-index: 0;
}

.header-meta .dropdown-profile li.active a, .footer-link li.active a{
    color: #0d799d;
}
/* Tauseef Css */
.custom-tabs .small_preadcumb span {
    font-size: 14px;
    color: #4c4c4c;
}
.custom-tabs .main_label_btn {
    display: flex;
    gap: 10px;
    align-items: center;
    flex-wrap: wrap;
}

.custom-tabs .label_date h3 {
    font-size: 30px;
    color: rgb(9, 9, 59);
    font-family: 'Lato', sans-serif;
}

.custom-tabs .main_label_btn .label_btn{
    background: #f0eeee;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    padding: 4px 12px;
    border-radius: 20px;
    font-weight: 600;
}
.custom-tabs .main_label_btn .label_btn.active{
    cursor: pointer;
    background-color:#0d799d;
    color: #fff;
}
.custom-tabs .main_label_btn .more_btn{
    cursor: pointer;
}
.custom-tabs .location{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
}
.location_name h4{
    font-size: 22px;
    color: rgb(9, 9, 59); 
    font-family: 'Lato', sans-serif;
}
.custom-tabs .location .label_btn{
    background: #f0eeee;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    padding: 4px 20px;
    border-radius: 20px;
    font-weight: 600;
}

.custom-column-gap{
    column-gap: 30px;
}
.accordian_content strong{
    min-width:75px;
}
.truncate-ellipsis {
    width:100%;
    max-width: 400px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }


.footer-widget p{
    margin-top: 3px;
    margin-bottom: 0;
    line-height: 26px;
}
.footer-widget p a:hover{
    color: white;
}

@media screen and  (max-width:592px) {
    .truncate-ellipsis {
        width:100%;
        max-width: 280px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
      }
    
}
/* Tauseef Css */




.custom-tabs  .nav-tabs .nav-item .nav-link svg {
    margin-right: 5px;
    font-size: 22px;
}
.breadCrumbActive{
    color: #0d799d !important;
}

.truncate-ellipsis {
    width:100%;
    max-width: 400px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }
  @media screen and  (max-width:592px) {
    .truncate-ellipsis {
        width:100%;
        max-width: 280px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
      }
    
}


